
















































































import { Vue, Component } from 'vue-property-decorator'
import { Pswp as PswpType, RotateDirection, CurrentPswpItem, PswpProps } from '@/type'
import {
    Event,
    getContainSize,
    getScale,
    getCalculatedScale,
    getTransformDeg,
    getContainerSize,
    modernize,
    transitionEndEventName,
    CurrentPswp,
} from '@/utils'

const TRANSITION_CLASS = 'pswp__img--transition'

@Component({ name: 'Pswp' })
export default class Pswp extends Vue {
    rotate: boolean = false
    isRotateTransform: boolean = false

    handleRotate(direction: RotateDirection) {
        const pswp = CurrentPswp.get() as PswpType
        const { container } = pswp
        const currentItem = pswp.currItem as CurrentPswpItem
        const img = currentItem.container.lastChild as HTMLImageElement

        if (!currentItem.loaded || this.isRotateTransform) return

        pswp.updateSize(false)

        const [deg, transformDeg] = getTransformDeg(img, direction)
        const isVertical = transformDeg % 180 !== 0
        currentItem.verticalRotated = isVertical
        const rotate = `rotate(${transformDeg}deg)`

        const containerSize = getContainerSize(container, currentItem)
        const [animatedScale, verticalSilencedScale] = getCalculatedScale(containerSize, img, isVertical)

        const handleTransitionend = () => {
            img.removeEventListener(transitionEndEventName, handleTransitionend)
            img.classList.remove(TRANSITION_CLASS)

            const { naturalHeight, naturalWidth } = img
            if (isVertical) {
                currentItem.w = naturalHeight
                currentItem.h = naturalWidth
                img.style[modernize('transform')] = rotate + verticalSilencedScale
            } else {
                currentItem.w = naturalWidth
                currentItem.h = naturalHeight
                img.style[modernize('transform')] = rotate
            }
            pswp.updateSize(false)
            this.isRotateTransform = false
        }

        img.addEventListener(transitionEndEventName, handleTransitionend)
        img.classList.add(TRANSITION_CLASS)
        this.isRotateTransform = true
        img.style[modernize('transform')] = rotate + animatedScale
    }

    created() {
        Event.on('opened', (pswpProps: PswpProps) => {
            if (pswpProps.rotate) this.rotate = true
            const pswp = CurrentPswp.get() as PswpType
            pswp.listen('destroy', () => {
                this.rotate = false
            })
        })
    }
}
